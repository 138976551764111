<template>
    <div>
        <MainLayout :vh_header="true" :is-show-header=false>
           Messages
        </MainLayout>
    </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import {errorMessage} from "../services/messages";
import {mapMutations} from "vuex";

export default {
    name: 'Messages',
    components: {
        MainLayout,
    },
    data() {
        return {
        }
    },
    mounted() {
        this.getUser();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        async getUser() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}user`);
                this.setUser(response?.data?.data || {});
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
    },
}
</script>
